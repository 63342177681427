import React, { useContext } from 'react';
import { Container } from '../../container/container';
import { BlockContent } from '../../block-content/block-content';
import { getUrlFromNavItem } from '../../../lib/helpers';
import { LinkButton } from '../../button/link-button';
import { LayoutContext } from '../../../contexts/LayoutContext';
import IcnGear from '../../../images/icons/services/icon-gear.svg';
import IcnList from '../../../images/icons/services/icon-list.svg';
import IcnRoller from '../../../images/icons/services/icon-roller.svg';
import styles from './hero-services-teaser-section.module.scss';

const items = [
  {
    icon: IcnRoller,
    text: 'Ständiges Verbessern von Arbeitsabläufen',
    alt: 'Verbessern',
  },
  {
    icon: IcnGear,
    text: 'Einsatz neuer Maschinentechnik',
    alt: 'Maschinentechnik',
  },
  {
    icon: IcnList,
    text: 'Vielzahl theoretischer und praktischer Schulungen',
    alt: 'Schulungen',
  },
];

export function HeroServicesTeaserSection({ node }) {
  const {
    title,
    content,
    cta: {
      label: ctaLabel,
      reference: {
        id: ctaPageId,
        _type: ctaPageType,
        slug: { current: ctaSlug },
      },
    },
  } = node;

  const { startPageId } = useContext(LayoutContext);

  return (
    <Container variant="thin" className={styles.container}>
      <div className={styles.textCol1}>
        <div className={styles.textCol1Wrapper}>
          <h2>{title}</h2>
          <BlockContent blocks={content} />
        </div>
        <LinkButton
          className={styles.callToAction}
          to={getUrlFromNavItem({ id: ctaPageId, type: ctaPageType, slug: ctaSlug }, startPageId)}
        >
          {ctaLabel}
        </LinkButton>
      </div>
      <div className={styles.textCol2}>
        <p>Unser, im September 2015 gegründetes Unternehmen zeichnet sich insbesondere durch:</p>
        <ul className={styles.iconList}>
          {items.map(({ icon, text, alt }, idx) => (
            <li key={`list-item-${idx}`}>
              <div className={styles.iconWrapper}>
                <img src={icon} alt={alt} role="presentation" width="86" height="76" />
              </div>
              <h4 className={styles.textWrapper}>{text}</h4>
            </li>
          ))}
        </ul>
        <p>Auf diese Weise verbessern wir unsere Qualität und damit den Nutzen für Sie als unseren Kunden.</p>
      </div>
    </Container>
  );
}
