import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styles from './private-testimonial.module.scss';
import { ImageGrid } from './image-grid';
import { format } from 'date-fns';
import de from 'date-fns/locale/de';
import { chunk } from '../../lib/helpers';

export const PrivateTestimonial = () => {
  const { allSanityTestimonial } = useStaticQuery(graphql`
    {
      allSanityTestimonial(sort: { order: DESC, fields: completionDate }) {
        nodes {
          id
          testimonialText
          completionDate
          title
          clientName
          imageThumbs: imageList {
            _key
            _type
            asset {
              fluid(maxWidth: 300, maxHeight: 300) {
                ...GatsbySanityImageFluid
                aspectRatio
              }
              extension
            }
          }
          imageDetails: imageList {
            _key
            _type
            asset {
              fluid(maxWidth: 1920) {
                ...GatsbySanityImageFluid
                aspectRatio
              }
              extension
            }
          }
        }
      }
    }
  `);

  const { nodes } = allSanityTestimonial;

  const mappedOutput = nodes.map(
    ({ id, imageThumbs, imageDetails, title, testimonialText, clientName, completionDate }) => {
      return (
        <div className={styles.privateTestimonial} key={`${id}-key`}>
          <div className={styles.imageGridContainer}>
            <ImageGrid className={styles.imageGrid} imageThumbs={imageThumbs} imageDetails={imageDetails} />
          </div>

          <div>
            <h2>“{title}”</h2>
            <p>{testimonialText}</p>
            {clientName && <h4>{clientName}</h4>}
            {completionDate && (
              <i>
                {format(new Date(completionDate), 'MMMM yyyy', {
                  locale: de,
                })}
              </i>
            )}
          </div>
        </div>
      );
    }
  );
  const chunkedNodes = chunk(mappedOutput, 6);

  return (
    <div className={styles.privateTestimonials}>
      {chunkedNodes.map((output, index) => (
        <div key={`chunk-pt-${index}`}>{output}</div>
      ))}
    </div>
  );
};
