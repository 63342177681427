import React from 'react';
import { Container } from '../../container/container';
import styles from './blog-teaser-section.module.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { filterPostsByCategoryId } from '../../../lib/helpers';
import { BlogPostTeaserGrid } from '../../blogpost/blogpost-teaser-grid';

export function BlogTeaserSection({ node }) {
  const {
    title,
    subtitle,
    description,
    category: { _id: categoryId },
  } = node;

  const {
    allSanityPost: { edges: postEdges },
  } = useStaticQuery(graphql`
    {
      allSanityPost(
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
        limit: 100
        sort: { order: DESC, fields: publishedAt }
      ) {
        edges {
          node {
            title
            categories {
              language
              title
              id: _id
            }
            slug {
              current
            }
            publishedAt: publishedAt(formatString: "MMM D, YYYY")
            dateSegment: publishedAt(formatString: "YYYY/MM")
            mainImage {
              asset {
                id
                fluid(maxWidth: 480, maxHeight: 316) {
                  ...GatsbySanityImageFluid
                }
              }
              alt
            }
          }
        }
      }
    }
  `);

  // Static queries does not support params like the categoryId to filter it directly in GraphQL
  // Therefore we have to filter it after loading the data
  const posts = filterPostsByCategoryId(postEdges, categoryId).slice(0, 3);

  return (
    <Container variant="thin">
      <div className={styles.description}>
        <h5>{subtitle}</h5>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <BlogPostTeaserGrid postNodes={posts} />
    </Container>
  );
}
