import React, { useContext } from 'react';
import { Container } from '../../container/container';
import { ReasonsWhySection } from '../reasons-why-section/reasons-why-section';
import styles from './reasons-why-teaser-section.module.scss';
import { LinkButton } from '../../button/link-button';
import { LayoutContext } from '../../../contexts/LayoutContext';
import { getUrlFromNavItem } from '../../../lib/helpers';

export function ReasonsWhyTeaserSection({ node }) {
  const {
    title,
    cta: {
      label: ctaLabel,
      reference: {
        id: ctaPageId,
        _type: ctaPageType,
        slug: { current: ctaSlug },
      },
    },
  } = node;
  const { startPageId } = useContext(LayoutContext);

  return (
    <div className={styles.container}>
      <Container variant="thin" className={styles.headingContainer}>
        <h2>{title}</h2>
      </Container>
      <div className={styles.hider}>
        <ReasonsWhySection limit={6} />
      </div>
      <LinkButton
        variant="black"
        className={styles.callToAction}
        to={getUrlFromNavItem({ id: ctaPageId, type: ctaPageType, slug: ctaSlug }, startPageId)}
      >
        {ctaLabel}
      </LinkButton>
    </div>
  );
}
