import React, { useCallback } from 'react';
import { Container } from '../container/container';
import styles from './footer.module.scss';
import footerBlob from '../../images/blobs/footer-blob.svg';
import footerCircle from '../../images/blobs/footer-circle.svg';
import backToTop from '../../images/icons/back-to-top.svg';
import { ExternalLinkButton } from '../button/external-link-button';
import { InternalLink } from '../link/internal-link';
import { BlockContent } from '../block-content/block-content';

export function Footer(props) {
  const { companyInfo, socialMedia, footerLinks, footerCtaText } = props;
  const { phone } = companyInfo;

  const handleBackToTop = useCallback(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <footer className={styles.footerWrapper}>
      <Container className={styles.container} variant="thin">
        <img
          className={styles.footerCircle}
          src={footerCircle}
          width="217"
          height="217"
          alt="Footer background"
          role="presentation"
        />
        <img
          className={styles.footerBlob}
          src={footerBlob}
          width={434}
          height={503}
          alt="Footer background"
          role="presentation"
        />
        <div className={styles.blocksContainer}>
          <BlockContent blocks={footerCtaText} />
        </div>
        <ExternalLinkButton
          className={styles.callToAction}
          href={`tel:${phone.replace(/ +/g, '')}`}
          targetBlank={false}
        >
          {`Anrufen ${phone}`}
        </ExternalLinkButton>
        <div className={styles.linksContainer}>
          <div className={styles.linkItems}>
            <div className={styles.socialLinks}>
              {socialMedia.map(link => (
                <a key={`${link.name}-key`} href={link.url}>
                  {link.name}
                </a>
              ))}
            </div>
            <div className={styles.footerLinks}>
              {footerLinks.map(link => (
                <InternalLink variant="thin" key={`${link.name}-key`} to={link.link}>
                  {link.name}
                </InternalLink>
              ))}
              <p>© {new Date().getFullYear()} by Ma-Ka-Bau - All rights reserved</p>
            </div>
          </div>
          <button
            name="back-to-top"
            aria-label="Back to top button"
            className={styles.backToTop}
            onClick={handleBackToTop}
          >
            <img src={backToTop} width="19" height="12" alt="Back to top icon" role="presentation" />
          </button>
        </div>
      </Container>
    </footer>
  );
}
