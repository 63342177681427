import React from 'react';
import { graphql } from 'gatsby';
import '../styles/main.scss';
import { SEO } from '../components/seo';
import { renderSection } from '../components/sections/section-renderer';
import { LayoutContent } from '../components/layout/layout-content';
import { PageContext } from '../contexts/PageContext';

const ContentPage = ({ data = {} }) => {
  const { page = {} } = data;

  // console.log('PAGE CONTEXT', pageContext);

  const { sections, description, keywords, title } = page;

  return (
    <PageContext.Provider value={{ page }}>
      <LayoutContent>
        <SEO title={title} description={description} keywords={keywords} />
        {sections && sections.map(section => renderSection(section))}
      </LayoutContent>
    </PageContext.Provider>
  );
};

export default ContentPage;

export const query = graphql`
  query ContentPage($id: String!) {
    page: sanityPage(_id: { eq: $id }) {
      id: _id
      sections: _rawBody(resolveReferences: { maxDepth: 5 })
      mainImage {
        asset {
          id
          fluid(maxWidth: 1780) {
            ...GatsbySanityImageFluid
            aspectRatio
          }
          extension
        }
        alt
      }
      slug {
        current
      }
      description
      keywords
      title
    }
  }
`;
