import React from 'react';
import styles from './aspect-ratio-box.module.scss';
import clsx from 'clsx';

export function AspectRatioBox({ width, height, className, children }) {
  if (!width || !height) {
    return children;
  }

  const paddingTop = Math.floor((height / width) * 100);

  const rootStyle = {
    paddingTop: `${paddingTop}%`,
  };

  return (
    <div className={clsx(styles.root, className)} style={rootStyle}>
      <div className={styles.inner}>{children}</div>
    </div>
  );
}
