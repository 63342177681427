import React, { useState } from 'react';
import { AssetImg } from '../asset/asset-img';
import styles from './image-grid.module.scss';
import clsx from 'clsx';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';

export const ImageGrid = ({
  imageThumbs = [],
  imageDetails = [],
  gridItemClassName,
  hasGallery = true,
  gridAssetImageProps = {},
}) => {
  const [galleryIndex, setGalleryIndex] = useState(null);

  const gallery = imageDetails !== undefined ? imageDetails : [];
  const images = [...imageThumbs];
  const showImages = images.splice(0, 4);

  const thumbAssetImages = imageThumbs.map(({ asset }) => (
    <AssetImg asset={asset} className={styles.imageGalleryItem} role="presentation" />
  ));

  const detailAssetImages = gallery.map(({ asset }, index) => (
    <AssetImg
      asset={asset}
      className={styles.imageGalleryItem}
      imgStyle={{ objectFit: 'contain', objectPosition: '50% 50%' }}
      fadeIn={false}
      role="presentation"
    />
  ));

  return (
    <div className={styles.imageGrid}>
      {showImages.slice(0, 4).map(({ asset }, index) => {
        if (index > 4) return null;
        return index < 3 ? (
          <div
            onClick={() => setGalleryIndex(index)}
            className={clsx(styles.imageWrapper, gridItemClassName && gridItemClassName)}
            key={index}
          >
            <AssetImg asset={asset} className={styles.imageItem} {...gridAssetImageProps} />
          </div>
        ) : (
          <div
            onClick={() => setGalleryIndex(index)}
            className={clsx(styles.imageWrapper, gridItemClassName && gridItemClassName)}
            key={index}
          >
            <AssetImg asset={asset} className={styles.imageItem} {...gridAssetImageProps} />
            <div className={styles.overlay}>
              <span>+{images.length + 1}</span>
            </div>
          </div>
        );
      })}
      {hasGallery && (
        <div className={styles.imageGalleryContainer} style={{ display: galleryIndex !== null ? 'block' : 'none' }}>
          <button className={styles.imageGalleryClose} onClick={() => setGalleryIndex(null)}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.707107 14.8494C0.316582 14.4589 0.316582 13.8258 0.707107 13.4352L13.435 0.707305C13.8256 0.316781 14.4587 0.316781 14.8492 0.707305C15.2398 1.09783 15.2398 1.73099 14.8492 2.12152L2.12132 14.8494C1.7308 15.24 1.09763 15.24 0.707107 14.8494Z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.707107 0.7072C0.316582 1.09772 0.316582 1.73089 0.707107 2.12141L13.435 14.8493C13.8256 15.2399 14.4587 15.2399 14.8492 14.8493C15.2398 14.4588 15.2398 13.8256 14.8492 13.4351L2.12132 0.7072C1.7308 0.316676 1.09763 0.316676 0.707107 0.7072Z"
              />
            </svg>
          </button>

          <ImageGallery
            startIndex={galleryIndex}
            items={imageThumbs.map((item, index) => {
              return {
                original: item._key,
                renderItem: () => <div className={styles.imageGalleryItemWrapper}>{detailAssetImages[index]}</div>,
                thumbnail: item._key,
                renderThumbInner: () => thumbAssetImages[index],
                thumbnailClass: styles.thumbnail,
              };
            })}
            showThumbnails={true}
            showBullets={false}
            thumbnailPosition="bottom"
          />
        </div>
      )}
    </div>
  );
};
