import React from 'react';
import Img from 'gatsby-image';
import { isBlank, isFixedAsset, isFluidAsset } from '../../lib/helpers';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';
import clientConfig from '../../../client-config';
import { imageUrlFor } from '../../lib/image-url';

/**
 * Represents a proper image component based on the data provided by <code>asset</code> parameter
 *
 * @param asset Asset object of GraphQL result that represetns Sanity.io asses data structure
 * @param alt alt text of the image tag
 * @param nonStretch in case of fluid image avoiding stretched images using the fluid type.
 * @param aspectRatio fixed aspectRatio of the image
 * @param maxWidth maxWidth in case of that the asset is only the reference and should be resolved by id
 * @param other
 * @returns {null|*}
 * @constructor
 */
export function AssetImg({ asset, alt, nonStretch = false, aspectRatio, maxWidth = 1100, ...other }) {
  if (!asset) {
    return null;
  }

  const ariaProps = isBlank(alt)
    ? {
        alt: 'presentation image',
        role: 'presentation',
      }
    : {
        alt,
        role: undefined,
      };

  // check if the asses is correct fluid asset (and not just a reference)
  if (isFluidAsset(asset)) {
    if (asset.extension && asset.extension === 'svg') {
      return <img src={asset.fluid.src} {...other} alt={ariaProps.alt} role={ariaProps.role} />;
    }
    return <Img {...other} fluid={asset.fluid} alt={alt} {...getNonStrechedStyleProp(asset, nonStretch, other)} />;
  }

  // check if the asses is correct fixed asset (and not just a reference)
  if (isFixedAsset(asset)) {
    if (asset.extension && asset.extension === 'svg') {
      return <img src={asset.fixed.src} {...other} alt={ariaProps.alt} role={ariaProps.role} />;
    }
    return <Img fixed={asset.fixed} {...other} alt={ariaProps.alt} role={ariaProps.role} />;
  }

  // --------------------------------------
  // From here it could be only a reference from the AssetContext
  // --------------------------------------

  // check if it's a reference
  const assetId = asset._ref || asset._id;
  if (!assetId) {
    console.error('Asset ID not found on asset: ', asset);
    return null;
  }

  // Check if the image if a SVG
  if (asset.extension && asset.extension === 'svg') {
    return <img src={imageUrlFor(asset)} {...other} alt={ariaProps.alt} role={ariaProps.role} />;
  }

  const fluid = getFluidGatsbyImage(assetId, { maxWidth }, clientConfig.sanity);
  const otherSizesProps = {};
  if (aspectRatio) {
    otherSizesProps.aspectRatio = aspectRatio;
  }
  return (
    <Img
      fluid={fluid}
      {...other}
      alt={ariaProps.alt}
      role={ariaProps.role}
      {...getNonStrechedStyleProp(asset, nonStretch, other)}
      sizes={{
        ...fluid,
        ...otherSizesProps,
      }}
    />
  );
}

/**
 * Avoiding stretched images using the fluid type.
 *
 * As mentioned previously, images using the fluid type are stretched to match the container’s width. In the case
 * where the image’s width is smaller than the available viewport, the image will stretch to match the container,
 * potentially leading to unwanted problems and worsened image quality.
 *
 * see more details here: https://www.gatsbyjs.org/packages/gatsby-image/#avoiding-stretched-images-using-the-fluid-type
 *
 *
 * @param asset asset with presentationWidth field
 * @param nonStretch deactivate stretching or not
 * @param props props of AssetImg
 * @returns {{style: {}}|{style: {margin: string, maxWidth: ({type: any}|{type: *}|{type: *})}}}
 */
function getNonStrechedStyleProp(asset, nonStretch, props) {
  if (nonStretch && asset.fluid.presentationWidth) {
    return {
      style: {
        ...(props.style || {}),
        maxWidth: asset.fluid.presentationWidth,
        margin: '0 auto', // Used to center the image
      },
    };
  }

  return {
    style: { ...(props.style || {}) },
  };
}
