import React, { useState, useContext } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { Container } from '../../container/container';
import { ImageGrid } from '../../testimonials/image-grid';
import { format } from 'date-fns';
import de from 'date-fns/locale/de';
import { Bullets } from '../../testimonials/controls/bullets';
import { LinkButton } from '../../button/link-button';
import { getUrlFromNavItem } from '../../../lib/helpers';
import { LayoutContext } from '../../../contexts/LayoutContext';
import blob from '../../../images/blobs/testimonial-teaser.svg';
import styles from './testimonial-teaser-section.module.scss';

export function TestimonialTeaserSection({ node }) {
  const {
    title: sectionTitle,
    testimonialsReferences,
    cta: {
      label: ctaLabel,
      reference: {
        id: ctaPageId,
        _type: ctaPageType,
        slug: { current: ctaSlug },
      },
    },
  } = node;

  const [selectedSlide, setSelectedSlide] = useState(0);
  const { startPageId } = useContext(LayoutContext);

  return (
    <Container className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <img className={styles.blob} src={blob} role="presentation" alt="Testimonial teaser background" />
        <div className={styles.sliderContainer}>
          <ImageGallery
            className={styles.slider}
            startIndex={selectedSlide}
            onSlide={index => setSelectedSlide(index)}
            items={testimonialsReferences.map((item, index) => {
              const { _id, title, clientName, testimonialText, completionDate, imageList } = item;

              return {
                original: _id,
                renderItem: () => (
                  <Container className={styles.testimonialItem}>
                    <div className={styles.imageGridContainer}>
                      <ImageGrid
                        hasGallery={false}
                        imageThumbs={imageList}
                        gridItemClassName={styles.gridItem}
                        gridAssetImageProps={{
                          aspectRatio: 10 / 9,
                        }}
                        // imageGridItemStyle={{
                        //   height: gridItemHeight,
                        // }}
                      />
                    </div>

                    <div className={styles.textContainer}>
                      <h4>{sectionTitle}</h4>
                      <h2>“{title}”</h2>
                      <p className={styles.testimonialText}>{testimonialText}</p>
                      <h4 className={styles.clientName}>{clientName}</h4>
                      <i>
                        {completionDate &&
                          format(new Date(completionDate), 'MMMM yyyy', {
                            locale: de,
                          })}
                      </i>
                      <br />
                    </div>
                  </Container>
                ),
              };
            })}
            showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton={false}
          />
        </div>
        <Bullets className={styles.bullets} selectedIndex={selectedSlide} items={testimonialsReferences} />
      </div>
      <LinkButton
        variant="black"
        className={styles.callToAction}
        to={getUrlFromNavItem({ id: ctaPageId, type: ctaPageType, slug: ctaSlug }, startPageId)}
      >
        {ctaLabel}
      </LinkButton>
    </Container>
  );
}
