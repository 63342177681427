import React from 'react';
import { InternalLink } from '../../link/internal-link';
import clsx from 'clsx';
import styles from './mobile-link.module.scss';

export function MobileLink({ link: { link, name }, onClick }) {
  return (
    <InternalLink to={link} className={clsx(styles.mobileLink, styles.specify)} onClick={() => onClick()}>
      {name}
    </InternalLink>
  );
}
