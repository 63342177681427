import React, { useContext, useState } from 'react';
import { AssetImg } from '../../asset/asset-img';
import { Container } from '../../container/container';
import styles from './hero-testimonial-teaser-section.module.scss';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { format } from 'date-fns';
import de from 'date-fns/locale/de';
import heroBlob from '../../../images/blobs/hero-1.svg';
import heroCircle from '../../../images/blobs/hero-2.svg';
import { LayoutContext } from '../../../contexts/LayoutContext';
import { getUrlFromNavItem } from '../../../lib/helpers';
import { LinkButton } from '../../button/link-button';
import { Bullets } from '../../testimonials/controls/bullets';

export function HeroTestimonialTeaserSection({ node = {} }) {
  const {
    testimonialsReferences,
    cta: {
      label: ctaLabel,
      reference: {
        id: ctaPageId,
        _type: ctaPageType,
        slug: { current: ctaSlug },
      },
    },
  } = node;

  const { startPageId } = useContext(LayoutContext);

  const [selectedSlide, setSelectedSlide] = useState(0);
  return (
    <div className={styles.heroContainer}>
      <Container className={styles.heroSliderContainer}>
        <ImageGallery
          startIndex={selectedSlide}
          onSlide={index => setSelectedSlide(index)}
          items={testimonialsReferences.map((item, index) => {
            const { _id, title, clientName, completionDate, testimonialImage } = item;
            return {
              original: _id,
              renderItem: () => (
                <div className={styles.heroSlideContainer}>
                  <AssetImg
                    imgStyle={{
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                    }}
                    className={styles.heroSlide}
                    asset={testimonialImage.asset}
                  />
                  <div className={styles.slideText}>
                    <h1 className={styles.slideTitle}>“{title}”</h1>
                    <h4 className={styles.clientName}>{clientName}</h4>
                    <i>
                      {completionDate &&
                        format(new Date(completionDate), 'MMMM yyyy', {
                          locale: de,
                        })}
                    </i>
                  </div>
                </div>
              ),
            };
          })}
          showThumbnails={false}
          showPlayButton={false}
          showFullscreenButton={false}
        />
        <div className={styles.actionContainer}>
          <Bullets selectedIndex={selectedSlide} items={testimonialsReferences} />
          <LinkButton
            className={styles.callToAction}
            to={getUrlFromNavItem({ id: ctaPageId, type: ctaPageType, slug: ctaSlug }, startPageId)}
          >
            {ctaLabel}
          </LinkButton>
        </div>
        <img className={styles.heroBlob} src={heroBlob} alt="Hero blob background" role="presentation" />
        <img className={styles.heroCircle} src={heroCircle} alt="Hero cirlce background" role="presentation" />
      </Container>
    </div>
  );
}
