import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { AssetImg } from '../../asset/asset-img';
import { Container } from '../../container/container';
import { BlockContent } from '../../block-content/block-content';
import styles from './services-section.module.scss';

export function ServicesSection({ type }) {
  const { allSanityService } = useStaticQuery(graphql`
    {
      allSanityService(sort: { order: ASC, fields: _createdAt }) {
        nodes {
          title
          description: _rawDescription
          icon {
            asset {
              fluid(maxWidth: 540) {
                ...GatsbySanityImageFluid
                aspectRatio
              }
              extension
            }
          }
        }
      }
    }
  `);

  const { nodes } = allSanityService;

  return (
    <Container variant="thin" className={styles.container}>
      {nodes.map(({ title, description, icon }, index) => {
        return (
          <div key={index} className={styles.serviceItem}>
            <AssetImg asset={icon.asset} className={styles.image} width={133} height={117} nonStretch={true} />
            <div className={styles.title}>
              <h2> {title} </h2>
            </div>
            <BlockContent blocks={description} />
          </div>
        );
      })}
    </Container>
  );
}
