import React, { useEffect, useState, useRef } from 'react';
import { animated as a, useSpring, useTrail, useChain, config } from 'react-spring';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import BurgerMenu from './mobile-menu/burger-menu';
import { MobileLink } from './mobile-menu/mobile-link';
import { Container } from '../container/container';
import { ExternalLinkButton } from '../button/external-link-button';
import { Link } from 'gatsby';
import styles from './header.module.scss';
import { useMedia } from '../../hooks/useMedia';
import { InternalLink } from '../link/internal-link';
import headerBlob from '../../images/blobs/header-blob.svg';
import Logo from '../../images/Ma-Ka-Bau-Logo.jpg';
import logoBackground from '../../images/blobs/logo-blob.svg';
import ctaBg from '../../images/blobs/num-8.svg';

export function Header(props) {
  const {
    menuItems = [],
    companyInfo: { phone },
  } = props;
  const [open, setOpen] = useState(false);

  const shouldCloseMenu = useMedia(['(min-width: 768px)'], [true], false);
  useEffect(() => {
    if (open) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      disableBodyScroll(document.getElementsByTagName('body')[0]);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [open]);

  useEffect(() => {
    if (shouldCloseMenu) {
      setOpen(false);
    }
  }, [shouldCloseMenu]);

  const listTrailRef = useRef();
  const listTrail = useTrail(menuItems.length, {
    opacity: open ? 1 : 0,
    transform: open ? 'translate(0px, 0px) scale(1)' : 'translate(30px, -30px) scale(0.6)',
    config: {
      tension: 450,
      clamp: open ? false : true,
    },
    ref: listTrailRef,
  });

  const menuCoverRef = useRef();
  const menuCoverProps = useSpring({
    transform: `scale(${open ? 1 : 0})`,
    config: { ...config.gentle, clamp: true },
    ref: menuCoverRef,
  });

  const mobileCtaRef = useRef();
  const mobileCtaBlobProps = useSpring({
    transform: `translateY(${open ? 0 : 100}%)`,
    ref: mobileCtaRef,
  });

  useChain(
    open ? [menuCoverRef, listTrailRef, mobileCtaRef] : [mobileCtaRef, listTrailRef, menuCoverRef],
    open ? [0, 0.1, 0] : [0, 0, 0.2]
  );

  return (
    <div className={styles.headerWrapper}>
      <Container component="header" className={styles.header}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <img src={logoBackground} className={styles.logoBackground} alt="logo background" role="presentation" />
            <img src={Logo} className={styles.logoImg} alt="Ma Ka Bau Logo" />
          </Link>
        </div>

        <div className={styles.nav}>
          <div className={styles.linksContainer}>
            {menuItems.map(({ link, name }, index) => {
              return (
                <InternalLink key={`${link}-${index}-key`} to={link}>
                  {name}
                </InternalLink>
              );
            })}
          </div>
          {phone && (
            <div className={styles.ctaContainer}>
              <img className={styles.headerBlob} src={headerBlob} alt="header blob" role="presentation" />
              <ExternalLinkButton
                className={styles.callToAction}
                href={`tel:${phone.replace(/ +/g, '')}`}
                targetBlank={false}
              >
                {`Anrufen ${phone}`}
              </ExternalLinkButton>
            </div>
          )}
        </div>

        <BurgerMenu isOpen={open} onClick={() => setOpen(!open)} />
      </Container>
      <div style={{ pointerEvents: open ? 'all' : 'none' }} className={styles.mobileMenuContainer}>
        <a.div style={menuCoverProps} className={styles.cover} />
        <Container className={styles.linksContainer} variant="normal">
          {listTrail.map((trailProp, idx) => {
            const link = menuItems[idx];
            return (
              <a.div className={styles.animationContainer} key={`link-${idx}-${link}`} style={trailProp}>
                <MobileLink link={link} onClick={() => setOpen(false)} />
              </a.div>
            );
          })}
        </Container>
        {phone && (
          <a.div style={mobileCtaBlobProps} className={styles.ctaMobileContainer}>
            <a.img src={ctaBg} alt="Mobile call to action background" role="presentation" />
            <a.div style={mobileCtaBlobProps}>
              <ExternalLinkButton href={`tel:${phone.replace(/ +/g, '')}`} targetBlank={false}>
                {`Anrufen ${phone}`}
              </ExternalLinkButton>
            </a.div>
          </a.div>
        )}
      </div>
    </div>
  );
}
