import React from 'react';
import clsx from 'clsx';
import { AssetImg } from '../asset/asset-img';
import { useStaticQuery, graphql } from 'gatsby';
import { format } from 'date-fns';
import de from 'date-fns/locale/de';
import styles from './b2b-testimonial.module.scss';
import { ImageGrid } from './image-grid';

export function B2bTestimonial() {
  const { allSanityB2BTestimonial } = useStaticQuery(graphql`
    {
      allSanityB2BTestimonial(sort: { order: ASC, fields: _createdAt }) {
        nodes {
          id
          testimonialText
          companyName
          companyWebsiteUrl
          completionDate
          title
          personName
          imageThumbs: imageList {
            _key
            _type
            asset {
              fluid(maxWidth: 300, maxHeight: 300) {
                ...GatsbySanityImageFluid
                aspectRatio
              }
              extension
            }
          }
          imageDetails: imageList {
            _key
            _type
            asset {
              fluid(maxWidth: 1980) {
                ...GatsbySanityImageFluid
                aspectRatio
              }
              extension
            }
          }
          logo {
            _key
            _type
            asset {
              fluid(maxWidth: 540) {
                ...GatsbySanityImageFluid
                aspectRatio
              }
              extension
            }
          }
        }
      }
    }
  `);

  const { nodes } = allSanityB2BTestimonial;

  return (
    <div className={styles.parentRowWrapper}>
      {nodes.map(
        ({ logo, imageThumbs, testimonialText, personName, completionDate, imageDetails, companyName }, index) => (
          <div className={styles.parentColumn} key={index}>
            <div
              className={clsx(
                styles.logoWrapper,
                imageThumbs.length === 0 && styles.greyBackground,
                imageThumbs.length > 0 && styles.noBackground
              )}
            >
              <AssetImg
                asset={logo.asset}
                className={styles.image}
                imgStyle={{ objectFit: 'contain', objectPosition: '50% 50%' }}
              />
            </div>
            <div className={styles.textWrapper}>
              {testimonialText && <p>{testimonialText}</p>}
              {(personName || companyName) && <h4>{personName || companyName}</h4>}
              {completionDate && (
                <i>
                  {format(new Date(completionDate), 'MMMM yyyy', {
                    locale: de,
                  })}
                </i>
              )}
            </div>
            <ImageGrid imageThumbs={imageThumbs} imageDetails={imageDetails} />
          </div>
        )
      )}
    </div>
  );
}
