import React from 'react';
import styles from './bullets.module.scss';
import clsx from 'clsx';

export function Bullets({ items, selectedIndex, className }) {
  return (
    <div className={clsx(styles.bullets, className)}>
      {items.map(({ _id }, index) => (
        <span key={_id} className={clsx(styles.bullet, index === selectedIndex && styles.activeBullet)} />
      ))}
    </div>
  );
}
