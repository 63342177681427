import React from 'react';
import { Link } from 'gatsby';
import styles from './internal-link.module.scss';
import clsx from 'clsx';

export function InternalLink({ className, children, to, variant = 'normal', ...other }) {
  return (
    <Link
      className={clsx(styles.internalLink, variant === 'thin' && styles.thin, className)}
      activeClassName={styles.active}
      to={to}
      {...other}
    >
      {children}
    </Link>
  );
}
