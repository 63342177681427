import React, { useContext } from 'react';
import clsx from 'clsx';
import { Container } from '../../container/container';
import { BlockContent } from '../../block-content/block-content';
import { AspectRatioBox } from '../../container/aspect-ratio-box';
import { AssetImg } from '../../asset/asset-img';
import { PageContext } from '../../../contexts/PageContext';
import { getUrlFromNavItem } from '../../../lib/helpers';
import { LinkButton } from '../../button/link-button';
import { LayoutContext } from '../../../contexts/LayoutContext';
import styles from './content-page-teaser-section.module.scss';

export function ContentPageTeaserSection({ node }) {
  const {
    content,
    image = {},
    imagePlacement,
    cta: {
      label: ctaLabel,
      reference: {
        id: ctaPageId,
        _type: ctaPageType,
        slug: { current: ctaSlug },
      },
    },
  } = node;
  const {
    page: {
      slug: { current: pageSlug },
    },
  } = useContext(PageContext);

  const { startPageId } = useContext(LayoutContext);

  const dataBlobAttributes = {
    [`data-blob-${imagePlacement}`]: pageSlug,
  };

  return (
    <div className={styles.containerWrapper}>
      <Container
        variant="thin"
        className={clsx(
          styles.container,
          imagePlacement === 'right' && styles.right,
          imagePlacement === 'left' && styles.left
        )}
        {...dataBlobAttributes}
      >
        <div className={styles.imageContainer}>
          <AspectRatioBox width={1} height={1} className={styles.imageWrapper}>
            <AssetImg asset={image.asset} alt={image.alt} className={styles.image} />
          </AspectRatioBox>
        </div>
        <div className={styles.text}>
          <BlockContent blocks={content} />
          <LinkButton
            className={styles.callToAction}
            to={getUrlFromNavItem({ id: ctaPageId, type: ctaPageType, slug: ctaSlug }, startPageId)}
          >
            {ctaLabel}
          </LinkButton>
        </div>
      </Container>
    </div>
  );
}
