import React from 'react';
import { Container } from '../../container/container';
import styles from './page-headline-section.module.scss';

export function PageHeadlineSection({ node = {} }) {
  const { layout, title, colorGradient, subTitle } = node;

  /**
   * Consider fixing this in the studio, should just make all variants available on sanity.
   * Its not easy to predict the output if we do this but I'm not sure if it is necessary.
   * Do the same for the block-content-section, this is where it was based from.
   */
  const variantBaseOnLayout = layout === 'wide' ? 'thin' : layout === 'normal' ? 'thin' : 'extra-thin';

  const gradientClassName = colorGradient
    .split('-')
    .map((c, i) => (i === 1 ? c.charAt(0).toUpperCase() + c.slice(1) : c))
    .join('');

  return (
    <Container className={styles.container} variant={variantBaseOnLayout}>
      <h1 className={styles[gradientClassName]}>{title}</h1>
      <p>{subTitle}</p>
    </Container>
  );
}
