import React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import styles from './button.module.scss';

export function LinkButton({ className, children, variant, ...other }) {
  return (
    <Link className={clsx(styles.link, variant === 'black' && styles.black, className)} {...other}>
      <span>{children}</span>
    </Link>
  );
}
