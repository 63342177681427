import React from 'react';
import styles from './custom-block-content.module.scss';
import BaseBlockContent from '@sanity/block-content-to-react';

export function CustomBlockContent(customBlockContentNode) {
  const {
    node: { columnOne, columnTwo, columnThree },
  } = customBlockContentNode;

  return (
    <div className={styles.customBlockContent}>
      <BaseBlockContent blocks={columnOne.map((block, idx) => (idx === 0 ? { ...block, first: true } : block))} />
      <BaseBlockContent blocks={columnTwo.map((block, idx) => (idx === 0 ? { ...block, first: true } : block))} />
      <BaseBlockContent blocks={columnThree.map((block, idx) => (idx === 0 ? { ...block, first: true } : block))} />
    </div>
  );
}
