import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container } from '../../container/container';
import { chunk } from '../../../lib/helpers';
import { BlockContent } from '../../block-content/block-content';
import styles from './reasons-why-section.module.scss';

export function ReasonsWhySection({ node, limit }) {
  const {
    allSanityReasonWhy: { nodes },
  } = useStaticQuery(graphql`
    {
      allSanityReasonWhy(sort: { fields: number }) {
        nodes {
          title
          number
          description: _rawDescription
        }
      }
    }
  `);

  let limitedNodes = [];
  if (limit && typeof limit === 'number' && limit < nodes.length) {
    limitedNodes = nodes.slice(0, limit);
  } else {
    limitedNodes = nodes;
  }

  const chunkedNodes = chunk(limitedNodes, 8);

  return (
    <Container className={styles.container} variant="thin">
      {chunkedNodes.map((nodeBatch, index) => (
        <div key={`${index}_key`} className={styles.reasonBatchContainer}>
          {nodeBatch.map(node => (
            <div key={node.number} className={styles.reasonItem}>
              <div className={styles.number}>
                <h4>{node.number}</h4>
              </div>
              <div className={styles.textContainer}>
                <h4>{node.title}</h4>
                <BlockContent blocks={node.description} />
              </div>
            </div>
          ))}
        </div>
      ))}
    </Container>
  );
}
