import React from 'react';
import { BlogTeaserSection } from './blog-teaser-section/blog-teaser-section';
import { CompanyFactsSection } from './company-facts-section/company-facts-section';
import { BlockContentSection } from './block-content-section/block-content-section';
import { BlockTextImageSection } from './block-text-image-section/block-text-image-section';
import { ClientsSection } from './clients-section/clients-section';
import { TestimonialSection } from './testimonial-section/testimonial-section';
import { ServicesSection } from './services-section/services-section';
import { CtaSection } from './cta-section/cta-section';
import { IconListSection } from './icon-list-section/icon-list-section';
import { PageHeadlineSection } from './page-headline-section/page-headline-section';
import { HeroTestimonialTeaserSection } from './hero-testimonial-teaser-section/hero-testimonial-teaser-section';
import { HeroServicesTeaserSection } from './hero-services-teaser-section/hero-services-teaser-section';
import { TestimonialTeaserSection } from './testimonial-teaser-section/testimonial-teaser-section';
import { ContentPageTeaserSection } from './content-page-teaser-section/content-page-teaser-section';
import { ReasonsWhySection } from './reasons-why-section/reasons-why-section';
import { ReasonsWhyTeaserSection } from './reasons-why-teaser-section/reasons-why-teaser-section';
import { PartnerSection } from './partner-section/partner-section';
// TODO: reactive after new insta token
// import { InstagramFeedSection } from './instagram-feed-section/instagram-feed-section';

export function renderSection(sectionNode) {
  const sectionKey = `section-${sectionNode._key}`;

  switch (sectionNode._type) {
    case 'pageHeadlineSection':
      return <PageHeadlineSection key={sectionKey} node={sectionNode} />;
    case 'heroTestimonialTeaserSection':
      return <HeroTestimonialTeaserSection key={sectionKey} node={sectionNode} />;
    case 'heroServicesTeaserSection':
      return <HeroServicesTeaserSection key={sectionKey} node={sectionNode} />;
    case 'companyFactsSection':
      return <CompanyFactsSection key={sectionKey} node={sectionNode} />;
    case 'testimonialTeaserSection':
      return <TestimonialTeaserSection key={sectionKey} node={sectionNode} />;
    case 'blogTeaserSection':
      return <BlogTeaserSection key={sectionKey} node={sectionNode} />;
    case 'blockContentSection':
      return <BlockContentSection key={sectionKey} node={sectionNode} />;
    case 'blockTextImageSection':
      return <BlockTextImageSection key={sectionKey} node={sectionNode} />;
    case 'clientsSection':
      return <ClientsSection key={sectionKey} node={sectionNode} />;
    case 'testimonialSection':
      return <TestimonialSection key={sectionKey} node={sectionNode} />;
    case 'servicesSection':
      return <ServicesSection key={sectionKey} node={sectionNode} />;
    case 'ctaSection':
      return <CtaSection key={sectionKey} node={sectionNode} />;
    case 'iconListSection':
      return <IconListSection key={sectionKey} node={sectionNode} />;
    case 'reasonWhyTeaserSection':
      return <ReasonsWhyTeaserSection key={sectionKey} node={sectionNode} />;
    case 'reasonWhySection':
      return <ReasonsWhySection key={sectionKey} node={sectionNode} />;
    case 'contentPageTeaserSection':
      return <ContentPageTeaserSection key={sectionKey} node={sectionNode} />;
    case 'partnerSection':
      return <PartnerSection key={sectionKey} node={sectionNode} />;
    // TODO: reactive after new insta token
    // case 'instagramFeedSection':
    //   return <InstagramFeedSection key={sectionKey} node={sectionNode} />;
    default:
      return null;
  }
}
