import React from 'react';
import { Container } from '../../container/container';
import { B2bTestimonial } from '../../testimonials/b2b-testimonial';
import { PrivateTestimonial } from '../../testimonials/private-testimonial';
import styles from './testimonial-section.module.scss';

export function TestimonialSection({ node }) {
  const { testimonialType, title } = node;

  return (
    <Container variant="thin" className={styles.container}>
      <h2>{title}</h2>
      {testimonialType === 'b2b' ? <B2bTestimonial /> : <PrivateTestimonial />}
    </Container>
  );
}
