import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { AssetImg } from '../../asset/asset-img';
import { Container } from '../../container/container';
import { BlockContent } from '../../block-content/block-content';
import styles from './partner-section.module.scss';

export function PartnerSection({ type }) {
  const { allSanityPartner } = useStaticQuery(graphql`
    {
      allSanityPartner(sort: { order: ASC, fields: _createdAt }) {
        nodes {
          companyName
          description: _rawDescription
          companyWebsiteUrl
          logo {
            asset {
              fluid(maxWidth: 540) {
                ...GatsbySanityImageFluid
                aspectRatio
              }
              extension
            }
          }
        }
      }
    }
  `);

  const { nodes } = allSanityPartner;

  return (
    <Container variant="thin" className={styles.container}>
      {nodes.map(({ companyName, companyWebsiteUrl, description, logo }, index) => {
        return (
          <div key={index} className={styles.partnerItem}>
            {logo && logo.asset && (
              <AssetImg
                asset={logo.asset}
                className={styles.image}
                imgStyle={{ objectFit: 'contain', objectPosition: '50% 50%' }}
              />
            )}
            <div className={styles.companyName}>
              <h2> {companyName} </h2>
            </div>
            <div className={styles.description}>
              <BlockContent blocks={description} />
            </div>
          </div>
        );
      })}
    </Container>
  );
}
