import React from 'react';
import { Container } from '../../container/container';
import { BlockContent } from '../../block-content/block-content';
import styles from './block-content-section.module.scss';

export function BlockContentSection({ node = {} }) {
  const { title, content, layout } = node;
  const variantBaseOnLayout = layout === 'wide' ? 'thin' : 'extra-thin';
  return (
    <Container className={styles.container} variant={variantBaseOnLayout}>
      {title && <h2>{title}</h2>}
      <BlockContent blocks={content} />
    </Container>
  );
}
