export function filterPostsByCategoryId(postEdges, categoryId) {
  if (!postEdges) return [];
  return postEdges
    .map(edge => edge.node)
    .filter(post => post.categories && post.categories.find(category => category.id === categoryId));
}

export function getUrlFromNavItem({ id, type, slug, dateSegment }, startPageId) {
  switch (type) {
    case 'blogOverviewPage':
    case 'page':
      return id === startPageId ? '/' : `/${slug}/`;

    case 'post':
      return getBlogUrl(dateSegment, `/${slug}/`);

    default:
      return '/';
  }
}

export function getBlogUrl(dateSegment, slug) {
  return `/blog/${dateSegment}/${slug.current || slug}/`;
}

export function buildImageObj(source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export function isFluidImage(mainImage) {
  return mainImage && mainImage.asset && mainImage.asset.fluid;
}

export function isFluidAsset(asset) {
  return asset && asset.fluid;
}

export function isFixedImage(mainImage) {
  return mainImage && mainImage.asset && mainImage.asset.fixed;
}

export function isFixedAsset(asset) {
  return asset && asset.fixed;
}

export function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

export function chunk(array, size) {
  if (!array) return [];
  const firstChunk = array.slice(0, size); // create the first chunk of the given array
  if (!firstChunk.length) {
    return array; // this is the base case to terminal the recursive
  }
  return [firstChunk].concat(chunk(array.slice(size, array.length), size));
}

export function getExcerpt(str = '', limit = 100) {
  const fullText = str || '';
  let shortText = str || '';
  if (str && shortText.length > limit) {
    shortText = shortText.substr(0, shortText.lastIndexOf(' ', limit)) + '...';
  }
  return {
    fullText,
    shortText,
  };
}
