import React from 'react';
import styles from './figure.module.scss';
import { AssetImg } from '../asset/asset-img';

export function Figure({ asset, caption, alt }) {
  return (
    <figure className={styles.figure}>
      <AssetImg asset={asset} alt={alt} className={styles.image} />
      <figcaption>{caption}</figcaption>
    </figure>
  );
}
